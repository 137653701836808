@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{font-family:"Montserrat", sans-serif;box-sizing:border-box;margin:0;padding:0}body{background-color:#D8C5FF;color:rgba(255,255,255,0.8)}body .App{text-align:center;min-height:100vh;-webkit-backdrop-filter:blur(5px);backdrop-filter:blur(5px);display:flex;flex-direction:column;justify-content:flex-start;align-items:center;padding:24px}body .App .app-logo{width:100%;max-width:360px;object-fit:contain;box-shadow:0 10px 50px -10px rgba(0,0,0,0.5);margin-bottom:12px;background-color:white;border-radius:18px}body .App .icons{width:100%;max-width:360px;display:grid;grid-template-columns:1fr 1fr 1fr;grid-gap:24px;gap:24px;margin-top:24px;margin-bottom:24px}.SocialButton:last-child{grid-column:2}.SocialButton .link{width:100%;border-radius:18px;box-shadow:0 10px 30px -10px rgba(0,0,0,0.5);text-decoration:none;display:flex;justify-content:center;align-items:center;margin-bottom:8px;cursor:pointer}.SocialButton .link .icon{padding:8px;width:100%;display:flex;justify-content:center;align-items:center;position:relative}.SocialButton .link .icon:before{content:" ";display:block;padding-top:100%}.SocialButton .link .icon img{filter:url(#drop-shadow);-webkit-filter:drop-shadow(0 0 6px rgba(0,0,0,0.25));max-width:50px;max-height:50px}.SocialButton .link .content{display:flex;justify-content:center;align-items:center;display:none}.SocialButton .title{text-shadow:0 2px 8px rgba(0,0,0,0.5);display:none}

*{font-family:"Montserrat", sans-serif;box-sizing:border-box;margin:0;padding:0}body{background-color:#D8C5FF;color:rgba(255,255,255,0.8)}body .App{text-align:center;min-height:100vh;-webkit-backdrop-filter:blur(5px);backdrop-filter:blur(5px);display:flex;flex-direction:column;justify-content:flex-start;align-items:center;padding:24px}body .App .app-logo{width:100%;max-width:360px;object-fit:contain;box-shadow:0 10px 50px -10px rgba(0,0,0,0.5);margin-bottom:12px;background-color:white;border-radius:18px}body .App .icons{width:100%;max-width:360px;display:grid;grid-template-columns:1fr 1fr 1fr;grid-gap:24px;gap:24px;margin-top:24px;margin-bottom:24px}

