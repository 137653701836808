@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

$max-width: 360px;
$border-radius: 18px;

* {
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  // background-image: url("./media/images/jo-bg.jpg");
  // background-size: cover;
  // background-position: center;
  // background-repeat: no-repeat;
  // background-attachment: fixed;
  background-color: #D8C5FF;
  color: rgba(255, 255, 255, 0.8);

  .App {
    text-align: center;
    min-height: 100vh;
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 24px;

    .app-logo {
      width: 100%;
      max-width: $max-width;
      object-fit: contain;
      box-shadow: 0 10px 50px -10px rgba(0,0,0,0.5);
      margin-bottom: 12px;
      background-color: white;
      border-radius: $border-radius;
    }

    .icons {
      width: 100%;
      max-width: $max-width;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 24px;
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }
}
