@import "../../App.scss";

.SocialButton {
    &:last-child {
        grid-column: 2;
    }

    .link {
        width: 100%;
        border-radius: $border-radius;
        box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.5);
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 8px;
        cursor: pointer;

        .icon {
            padding: 8px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            &:before {
                content: " ";
                display: block;
                padding-top: 100%;
            }

            img {
                filter: url(#drop-shadow);
                -webkit-filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.25));
                max-width: 50px;
                max-height: 50px;
            }
        }

        .content {
            display: flex;
            justify-content: center;
            align-items: center;
            display: none;
        }
    }

    .title {
        text-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
        display: none;
    }
}
